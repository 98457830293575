import { useState, useEffect } from "react"
import { useDailyQuest } from "../daily-quest/useDailyQuest"
import LEVELS from "../levels/Levels"
import sendUserNotificationToast from "../toasts/user-notification-toast"

/**
 * Custom React hook for managing local Firestore state.
 * @param {Object} firebase - Firebase instance with required methods.
 * @returns {Object} - State variables and methods for interacting with local Firestore.
 */
const useLocalFirestore = firebase => {
  // states for local Firestore
  const [assessmentRecord, setAssessmentRecord] = useState([])
  const [activityRecord, setActivityRecord] = useState(null)
  const [storyMetricsRecord, setStoryMetricsRecord] = useState([])
  const [storyRatingsRecord, setStoryRatingsRecord] = useState([])
  const [notificationsRecord, setNotificationsRecord] = useState([])
  const [educatorNotificationsRecord, setEducatorNotificationsRecord] = useState([])
  const [educatorStudentGroups, setEducatorStudentGroups] = useState([])
  const [scheduledNotificationsRecord, setScheduledNotificationsRecord] = useState([])
  const [loadedActivities, setLoadedActivities] = useState([])
  const [dailyQuest, setDailyQuest] = useState([])
  const [dailyQuestId, setDailyQuestId] = useState(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [pointTotalRecord, setPointTotalRecord] = useState(null)
  const [level, setLevel] = useState(null)

  // loading states
  const [isQuestLoading, setIsQuestLoading] = useState(true)

  // misc variables and hooks
  const dailyQuestsData = useDailyQuest()

  useEffect(() => {
    /**
     * Initialize the local Firestore state.
     * Fetches user activity record, story ratings record, and story metrics record.
     */
    const init = async () => {
      const userId = firebase?.auth?._delegate?.currentUser?.uid || null

      if (isInitialized) return
      if (!userId) return

      initDailyQuestWithResponses()

      const storyRatingsRecordRes = await firebase.getUserStoryRatingsRecord(userId)
      const storyMetricsRecordRes = await firebase.getAllStoryMetrics()
      const assessmentRecordRes = await firebase.getAssessmentRecord(userId)

      setStoryMetricsRecord(storyMetricsRecordRes?.data?.storyMetrics || [])
      setStoryRatingsRecord(storyRatingsRecordRes?.data?.storyRatings || [])
      setAssessmentRecord(assessmentRecordRes || [])

      setIsInitialized(true)
    }

    const initDailyQuestWithResponses = async () => {
      setIsQuestLoading(true)
      try {
        const { question_playlist, active_group } = dailyQuestsData.find(({ locale }) => locale === "en-US")?.metadata || {}
        const todaysQuestId = question_playlist?.[active_group].id
        const todaysQuestStoryImage = question_playlist?.[active_group]?.metadata?.daily_quest_story?.metadata?.story_personal_photo?.imgix_url
        const todaysQuestStorySlug = question_playlist?.[active_group]?.metadata?.daily_quest_story?.slug

        // record of users responses to daily quest questions, may be incomplete or ordered different than displayed
        const dailyQuestResponseRecord = (await firebase.getDailyQuestRecord(todaysQuestId)) || []

        // record of users responses to daily quest questions, complete and ordered as displayed
        const completeQuestData = question_playlist[active_group].metadata.daily_quest_questions.map(question => {
          const matchingValueFromRecord = dailyQuestResponseRecord?.questResponses?.find(response => response.promptId === question.id) || null

          return {
            ...question.metadata,
            promptId: question.id,
            response: matchingValueFromRecord?.response || null,
            storyImage: todaysQuestStoryImage,
            storySlug: todaysQuestStorySlug,
          }
        })
        setDailyQuestId(todaysQuestId)
        setDailyQuestRecord(completeQuestData)
      } catch (e) {
        console.error(e)
      }
      setIsQuestLoading(false)
    }

    init()
  }, [firebase?.auth?._delegate?.currentUser?.uid, isInitialized])

  /**
   * Add an activity to the local record and update Firestore.
   * @param {Object} activity - The activity to be added.
   */
  const addActivity = async activity => {
    setActivityRecord(prevActivityRecord => [...prevActivityRecord, activity])
    await firebase.setActivityCompletion(activity)
  }

  /**
   * Remove an activity from the local record and update Firestore.
   * @param {Object} param0 - The ID of the activity to be removed.
   */
  const removeActivity = async ({ slug }) => {
    const updatedActivityRecord = activityRecord.filter(activity => activity.slug !== slug)
    setActivityRecord(updatedActivityRecord)
    await firebase.unsetActivityCompletion({ slug })
  }

  /**
   * Set the daily quest record in the local state.
   * @param {Array} questPlaylist - The daily quest playlist to be set.
   */
  const setDailyQuestRecord = questPlaylist => {
    setDailyQuest(questPlaylist)
  }

  /**
   * Set the response for a specific prompt in the daily quest and update Firestore.
   * @param {Object} param0 - The response and prompt ID to be set.
   */
  const setDailyQuestResponse = async ({ response, promptId }) => {
    const activityIndex = dailyQuest.findIndex(question => question.promptId === promptId)

    setDailyQuest(prevDailyQuest => {
      const updatedQuest = [...prevDailyQuest]
      updatedQuest[activityIndex].response = response
      return updatedQuest
    })

    await firebase.setDailyQuest({
      questId: dailyQuestId,
      promptId,
      response,
    })
  }

  /**
   * Get the results of the daily quest.
   * @returns {Object} - Object containing total correct, total questions, and points awarded.
   */
  const getDailyQuestResults = () => {
    let totalCorrect = 0

    dailyQuest.forEach(question => {
      switch (question.question_type.value) {
        case "multiple choice":
          if (question.response === question.multiple_choice_answer) {
            totalCorrect++
          }
          break
        case "yes / no":
        case "open response":
          if (!!question.response) {
            totalCorrect++
          }
          break
        default:
          break
      }
    })

    let points = totalCorrect

    return {
      totalCorrect,
      totalQuestions: dailyQuest.length,
      pointsAwarded: points,
    }
  }

  /**
   * Get the completion status of the daily quest.
   * @returns {Object} - Object containing quest completion status and current question index.
   */
  const getDailyQuestCompletionStatus = () => {
    const currentQuestionIndex = dailyQuest.findIndex(prompt => prompt.response === null)

    if (currentQuestionIndex === -1) {
      return {
        questCompletionStatus: "COMPLETE",
        currentQuestionIndex: dailyQuest.length - 1,
      }
    } else {
      return {
        questCompletionStatus: "ACTIVE",
        currentQuestionIndex,
      }
    }
  }

  /**
   * Update the user's story ratings record and story metrics record.
   * @param {Object} param0 - Object containing story ID, rating, and name.
   * @returns {Object} - Object containing updated rating count and average.
   */
  const updateStoryRatingsRecord = async ({ storySlug, rating, name }) => {
    const storyMetrics = storyMetricsRecord.find(r => r.storySlug === storySlug)
    const storyMetricsIndex = storyMetricsRecord.findIndex(r => r.storySlug === storySlug)
    const userStoryRating = storyRatingsRecord.find(r => r.storySlug === storySlug)
    const userStoryRatingIndex = storyRatingsRecord.findIndex(r => r.storySlug === storySlug)

    let ratingCount
    let ratingAverage

    if (!storyMetrics && !userStoryRating) {
      ratingCount = 1
      ratingAverage = rating
    } else if (storyMetrics && !userStoryRating) {
      ratingCount = storyMetrics.ratingCount + 1
      ratingAverage = (storyMetrics.ratingAverage * storyMetrics.ratingCount + rating) / ratingCount
    } else if (!storyMetrics && userStoryRating) {
      ratingCount = 1
      ratingAverage = rating
    } else if (storyMetrics && userStoryRating) {
      ratingCount = storyMetrics.ratingCount
      ratingAverage = (storyMetrics.ratingAverage * storyMetrics.ratingCount - userStoryRating.rating + rating) / ratingCount
    }

    if (storyMetrics) {
      // Update metrics record if it exists
      setStoryMetricsRecord(prev => {
        const updatedMetrics = [...prev]
        updatedMetrics[storyMetricsIndex] = {
          storySlug,
          ratingAverage,
          ratingCount,
        }
        return updatedMetrics
      })
    } else {
      // Add new metrics record if it doesn't exist
      setStoryMetricsRecord(prev => [...prev, { storySlug, ratingAverage, ratingCount }])
    }

    if (userStoryRating) {
      // Update rating record if it exists
      setStoryRatingsRecord(prev => {
        const updatedRatings = [...prev]
        updatedRatings[userStoryRatingIndex] = { storySlug, rating, name }
        return updatedRatings
      })
    } else {
      // Add new rating record if it doesn't exist
      setStoryRatingsRecord(prev => [...prev, { storySlug, rating, name }])
    }

    return { ratingCount, ratingAverage }
  }

  /**
   * Mark all notifications as read and update Firestore. Needs to happen immediately here for quickest feedback
   */
  const markUserNotificationsAsRead = async () => {
    const notifications = notificationsRecord.map(notification => {
      return {
        ...notification,
        isRead: true,
      }
    })
    setNotificationsRecord(notifications)
    await firebase.markUserNotificationsAsRead()
  }

  const deleteUserNotification = async notificationId => {
    setNotificationsRecord(prevNotificationsRecord => prevNotificationsRecord.filter(notification => notification.notificationId !== notificationId))
    await firebase.deleteUserNotification({ notificationId })
  }

  // observes changes made to the scheduledNotificationsRecord array, if there are any then it will schedule a notification for publication at the time indicated by the publicationDate field
  // scheduled notifcations that are past their date will be published immediately, and removed from the scheduledNotificationsRecord array. This will not produce toast notifications if older than 10 seconds
  useEffect(() => {
    const isDateInThePast = date => {
      const now = new Date()
      return date < now
    }

    const waitUntilDateTime = targetDateTime => {
      return new Promise(resolve => {
        const now = new Date().getTime()
        const target = new Date(targetDateTime).getTime()
        const delay = target - now

        if (delay <= 0) {
          // If the target date is already passed, resolve immediately
          resolve()
        } else {
          // Otherwise, wait until the target date
          setTimeout(resolve, delay)
        }
      })
    }

    // Function to deliver a notification at specific time
    const deliverDelayedNotification = async (date, notificationId) => {
      await waitUntilDateTime(date)

      // firebase call to move into notifications array from scheduledNotifications array
      await firebase.publishScheduledUserNotification({ notificationId })
    }

    // Define a variable to hold the setTimeout IDs
    let timeouts = []

    scheduledNotificationsRecord.forEach(undeliveredNotification => {
      const timeObject = undeliveredNotification.publicationDate
      const notificationId = undeliveredNotification.notificationId

      // Convert nanoseconds to milliseconds
      const milliseconds = Math.floor(timeObject.nanoseconds / 1000000)

      // Create a new Date object and set its time
      const date = new Date(timeObject.seconds * 1000 + milliseconds)

      if (!isDateInThePast(date)) {
        const timeoutId = setTimeout(() => deliverDelayedNotification(date, notificationId), date.getTime() - new Date().getTime())
        timeouts.push(timeoutId) // Push the timeout ID to the array
      }
    })

    // Clear all the timeouts when the component unmounts or when scheduledNotificationsRecord changes
    return () => {
      timeouts.forEach(timeoutId => clearTimeout(timeoutId))
    }
  }, [scheduledNotificationsRecord])

  /**
   * Function for handling new notifications, called by the Firebase listener.
   * This function will check if a new notification is within 10 seconds of the current time and display a toast notification if so.
   *
   * @param {Array} newNotifications
   */
  const handleNewNotifications = newNotifications => {
    // setting local state with new notifications and performing a check to see if any notifications are new
    setNotificationsRecord(prevActivityRecord => {

      const findUnmatchedObjects = (arr1, arr2) => {
        // Create a map of ids from the first array for efficient lookup
        const idMap = {}
        arr1.forEach(obj => {
          idMap[obj.notificationId] = true
        })

        // Filter objects from the second array that don't have a matching id in the first array
        return arr2.filter(obj => !idMap[obj.notificationId])
      }

      // any new notifications that are not in the previous record, will be full record on initial load, and then only new notifications after that
      const unmatchedNotifications = findUnmatchedObjects(prevActivityRecord, newNotifications)

      // check each new notification to see if it was published within the last 10 seconds
      unmatchedNotifications.forEach(notification => {
        const timeObject = notification.publicationDate

        // Convert nanoseconds to milliseconds
        const milliseconds = Math.floor(timeObject.nanoseconds / 1000000)

        // Create a new Date object and set its time
        const date = new Date(timeObject.seconds * 1000 + milliseconds)

        const isWithinTenSeconds = (date) => {
          const now = new Date() // Get current time
          const difference = Math.abs(now.getTime() - date.getTime()) // Calculate difference in milliseconds
          return difference <= 15000 // Check if within 1 seconds (10000 milliseconds)
        }

        if (isWithinTenSeconds(date)) {
          sendUserNotificationToast(notification)
        }
      })

      return [...newNotifications]
    })
  }

  useEffect(() => {
    if (pointTotalRecord !== null) {
      const userLevel = LEVELS.findIndex(level => pointTotalRecord >= level.points[0] && pointTotalRecord < level.points[1])
      setLevel(userLevel)
    }
  }, [pointTotalRecord])

  useEffect(() => {
    const loadGroups = async () => {
      const data = await firebase?.getActiveGroupMembersById('student')
      return data
    }
    loadGroups()
      .then((response) => {
        if (response) {
          setEducatorStudentGroups(response?.data)
        }
      })
      .catch((error) => {
        console.error("Failed Loading Groups list:-", error)
      })
  }, [educatorNotificationsRecord])

  return {
    assessmentRecord,
    activityRecord,
    storyMetricsRecord,
    storyRatingsRecord,
    notificationsRecord,
    educatorNotificationsRecord,
    educatorStudentGroups,
    loadedActivities,
    dailyQuest,
    dailyQuestId,
    isInitialized,
    isQuestLoading,
    pointTotalRecord,
    level,
    setScheduledNotificationsRecord,
    setEducatorNotificationsRecord,
    addActivity,
    removeActivity,
    setNotificationsRecord,
    markUserNotificationsAsRead,
    deleteUserNotification,
    setActivityRecord,
    setLoadedActivities,
    setDailyQuestRecord,
    setDailyQuestId,
    setDailyQuestResponse,
    getDailyQuestResults,
    getDailyQuestCompletionStatus,
    updateStoryRatingsRecord,
    setPointTotalRecord,
    handleNewNotifications,
  }
}

export default useLocalFirestore
