import { useStaticQuery, graphql } from "gatsby"

export const useDailyQuest = () => {
  const { allCosmicjsDailyQuest } = useStaticQuery(graphql`
    query {
      allCosmicjsDailyQuest {
        nodes {
          slug
          id
          title
          locale
          metadata {
            active_group
            question_playlist {
              id
              metadata {
                daily_quest_story {
                  slug
                  metadata {
                    story_personal_photo {
                      imgix_url
                    }
                  }
                }
                daily_quest_questions {
                  id
                  metadata {
                    question_type {
                      value
                    }
                    question_prompt
                    question_prompt_es
                    multiple_choice_option_1
                    multiple_choice_option_2
                    multiple_choice_option_3
                    multiple_choice_option_4
                    multiple_choice_option_1_es
                    multiple_choice_option_2_es
                    multiple_choice_option_3_es
                    multiple_choice_option_4_es
                    open_response_answer
                    multiple_choice_answer
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return allCosmicjsDailyQuest.nodes
}
